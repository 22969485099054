import PrismicDOM from "prismic-dom"
import React from "react"
import IFrame from "react-iframe"
import Modal from "react-modal"
import styled from "styled-components"
import { useViewport } from "../hooks/useViewport"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "row",
  },
  overlay: {
    zIndex: 99999,
  },
}

const Text = styled.div`
  color: #212028;
  font-family: Lato;
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 26px;
  max-width: 329px;
  height: min-content;
  padding: 20px;
`

const ModalContainer = ({ isOpen, image, videoURL, caption, onClose }) => {
  const { width } = useViewport()
  const breakpoint = 768

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles}
      onRequestClose={onClose}
      ariaHideApp={false}
      onClose={onClose}
    >
      {videoURL ? (
        <IFrame
          url={videoURL}
          width={width <= breakpoint ? "320" : "854"}
          height={width <= breakpoint ? "180" : "480"}
          allowFullScreen={true}
        />
      ) : (
        <>
          {image?.data && (
            <>
              <img
                style={{ maxWidth: "40vw" }}
                src={image.data.image.url}
                alt={image.data.image.alt || "unavailable"}
              />
              <Text>{PrismicDOM.RichText.asText(caption)}</Text>
            </>
          )}
        </>
      )}
    </Modal>
  )
}

export default ModalContainer
